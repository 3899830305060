<template>
  <div class="parlay">
    <template v-if="parlayBets.length > 0">
      <div class="parlay__bets">
        <div v-for="bet in parlayBets" :key="bet.bet_id" class="parlay__bets__bet">
          <div>
            <BetMatch is-premium-user is-parlay :bet-match-info="getBetMatchInfo(bet)" />
          </div>
          <div class="parlay__bets__bet__info">
            <BetInfo is-premium-user is-parlay :result="bet.bet_suggested.suggestion" :round="getRound(bet)" />
          </div>
        </div>
      </div>
      <hr class="parlay__separator" />
      <Probability :percent="totalProbability" isParlay />
    </template>
    <div v-else-if="isParlayFilled" class="parlay__empty">
      <div class="parlay__empty__icon">
        <img src="/icons/icn-z.svg" alt="" />
      </div>
      <div class="parlay__empty__text">No existe un parlay recomendable en este momento</div>
    </div>
  </div>
</template>

<script>
import axios from 'axios';
// const twoHoursInMillis = 1000 * 60 * 60 * 2;
const betSuggestions = {
  1: 'Gana',
  2: 'Empate',
  3: 'Gana/Empata',
  4: 'Gana/Gana',
};
// const minimumProb = 70;
const betsCatalogKeys = {
  // win/draw home
  W_D_H: 1,
  // win/draw away
  W_D_A: 2,
  // lower than 70%
  L_T_70: 3,
};
const maxParlays = 3;

// function getEmptyBetsCatalog() {
//   const betsCatalog = new Map();
//   betsCatalog.set(betsCatalogKeys.W_D_H, []);
//   betsCatalog.set(betsCatalogKeys.W_D_A, []);
//   betsCatalog.set(betsCatalogKeys.L_T_70, []);
//   return betsCatalog;
// }

function sortByProb(betsList) {
  betsList.sort((a, b) => {
    return b.prob_actual - a.prob_actual;
  });
}

export default {
  name: 'Parlay',
  components: {
    BetMatch: () => import('@/components/Sections/GolTipster/BetMatch'),
    BetInfo: () => import('@/components/Sections/GolTipster/BetInfo'),
    Probability: () => import('@/components/Sections/GolTipster/Probability'),
  },
  data() {
    return {
      parlayBets: [],
      isParlayFilled: false,
    };
  },
  computed: {
    totalProbability() {
      const totalProbability = this.parlayBets.reduce((accumulator, bet) => {
        let currentProbability = accumulator;
        const probActual = bet.prob_actual / 100;
        currentProbability = currentProbability ? currentProbability * probActual : currentProbability + probActual;
        return currentProbability;
      }, 0);

      return (totalProbability * 100).toFixed(0);
    },
  },
  methods: {
    async fetchBets() {
      try {
        const response = await axios.get(
          'https://3ejrkiihni.execute-api.us-west-2.amazonaws.com/prod/parlays/free_pick',
        );
        return response.data.tournaments || [];
      } catch (e) {
        throw new Error(e);
      }
    },
    async fillBets() {
      try {
        // const limitTime = new Date().valueOf() + twoHoursInMillis;
        const betsByTournament = await this.fetchBets();
        // leagueMx id = 1
        let league = betsByTournament.find(tournament => tournament.tournament_id === 1);
        if (this.isLeagueEmpty(league)) {
          // mixto id = 0
          league = betsByTournament.find(tournament => tournament.tournament_id === 0);
        }
        if (this.isLeagueEmpty(league)) {
          return;
        }

        league.bets.forEach(bet => {
          bet.bet_suggested.suggestion = betSuggestions[bet.bet_subtype_id];
        });

        this.parlayBets = league.bets;

        // const betsCatalog = league.bets.reduce((accumulator, bet) => {
        //   bet.dateTimeMillis = new Date(bet.date).valueOf();
        //   bet.bet_suggested.suggestion = betSuggestions[bet.bet_subtype_id];
        //   if (bet.dateTimeMillis > limitTime) {
        //     if (bet.prob_actual > minimumProb) {
        //       if (bet.bet_subtype_id === 3) {
        //         let betsList = null;
        //         if (bet.bet_suggested.home_team_logo) {
        //           betsList = accumulator.get(betsCatalogKeys.W_D_H);
        //         } else if (bet.bet_suggested.away_team_logo) {
        //           betsList = accumulator.get(betsCatalogKeys.W_D_A);
        //         }
        //         if (betsList) {
        //           betsList.push(bet);
        //         }
        //       }
        //     } else {
        //       const lowerThan70 = accumulator.get(betsCatalogKeys.L_T_70);
        //       lowerThan70.push(bet);
        //     }
        //   }
        //   return accumulator;
        // }, getEmptyBetsCatalog());
        // this.parlayBets = this.getParlay(betsCatalog);
      } catch (e) {
        throw new Error(e);
      } finally {
        this.isParlayFilled = true;
      }
    },
    isLeagueEmpty(league) {
      return !league.bets || !league.bets.length;
    },
    getParlay(betsCatalog) {
      const parlayBets = [];
      const betsCatalogValuesSorted = Object.values(betsCatalogKeys);
      betsCatalogValuesSorted.sort();
      for (const betsCatalogValue of betsCatalogValuesSorted) {
        if (parlayBets.length < maxParlays) {
          this.fillParlays(parlayBets, betsCatalog.get(betsCatalogValue));
        } else {
          break;
        }
      }
      return parlayBets;
    },
    fillParlays(parlays, betsList) {
      sortByProb(betsList);
      for (const bet of betsList) {
        if (!parlays.some(parlayBet => parlayBet.game_id === bet.game_id)) {
          parlays.push(bet);
          if (parlays.length >= maxParlays) {
            break;
          }
        }
      }
    },
    getBetMatchInfo(bet) {
      return {
        homeTeamAcronym: bet.home_team_acronym,
        homeTeamLogo: bet.home_team_logo,
        isHomeTeamChecked: bet.bet_suggested.home_team_logo,
        awayTeamAcronym: bet.away_team_acronym,
        awayTeamLogo: bet.away_team_logo,
        isAwayTeamChecked: bet.bet_suggested.away_team_logo,
      };
    },
    getRound(bet) {
      const round = bet.matchday_name ? bet.matchday_name.split(' ').pop() : null;
      const isRegularSeason = bet.matchday_abbreviation.toLowerCase().includes('m');
      return round && (bet.tournament_id !== 1 || isRegularSeason) ? round : null;
    },
  },
  beforeMount() {
    this.fillBets();
  },
};
</script>

<style scoped lang="scss">
.parlay {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-bottom: 0.5rem;

  &__bets {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    margin-top: 2rem;

    &__bet {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      margin: 0 1.75rem;

      &__info {
        margin-top: 1.2rem;
      }

      @media screen and (max-width: 562px) {
        margin: 0 0.9rem;
      }

      @media screen and (max-width: 508px) {
        margin: 0 0.4rem;
      }
    }
  }

  &__separator {
    width: 80%;
    border: dotted 1px #c7c7c7;
    margin: 1.5rem 0 0.5rem;
  }

  &__empty {
    display: flex;
    flex-direction: column;
    align-items: center;

    &__icon {
      margin-top: 6em;
      height: 4em;
      width: 4em;
      padding: 0.75em;
      border-radius: 12px;
      background-color: #dbe1ec;
      text-align: center;

      & > img {
        object-fit: contain;
      }
    }

    &__text {
      margin: 1.87em 0 5em;
      font-size: 1.5em;
      font-family: Circular-Std-Book, sans-serif;
      letter-spacing: -1.45px;
      text-align: center;
      color: #adadad;
    }
    @media screen and (max-width: 439px) {
      font-size: 0.8rem !important;
    }

    @media screen and (max-width: 387px) {
      font-size: 0.7rem !important;
    }

    @media screen and (max-width: 335px) {
      font-size: 0.6rem !important;
    }
  }
}
</style>
